import { useEffect } from 'react';

function App() {
  useEffect(() => {
    window.location = 'https://bestattorneys.com/?lang=es';
  }, []);
  return <></>;
}

export default App;
